<template>
	<div style="height: 100%;">
		<main-table>
			<el-form slot="header" :inline="true" :model="params" :size="theme.size">
				<el-form-item >
					<el-input v-model="params.keyword" placeholder="请输入流水" clearable></el-input>
				</el-form-item>
				<el-form-item v-if="common_types.length > 1">
					<el-select v-model="params.common_type" placeholder="请选择关联" clearable filterable>
						<el-option v-for="(status, s) in common_types" :key="s" :label="status" :value="s"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.employees" placeholder="请选择申请人" filterable  multiple collapse-tags>
						<el-option v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.purpose" placeholder="请选择用途" filterable  multiple collapse-tags>
						<el-option v-for="(status, s) in purposes" :key="s" :label="status" :value="s"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.status" placeholder="请选择状态" filterable  multiple collapse-tags>
						<el-option v-for="(status, s) in statuses" :key="s" :label="status" :value="s"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getExpenses({ ...params, page: 1})">查询</el-button>
					<el-button type="primary" @click="showExpense({common_type: 'App\\Models\\Order'})">申请</el-button>
				</el-form-item>
			</el-form>
			<el-table slot="table" ref="expense_table" class="bill scroll-wrapper absolute" height="100%" :data="expenses" :size="theme.size">
				<el-table-column label="报销人" min-width="90">
					<template slot-scope="scope">
						<p>{{scope.row.employee.name}}</p>
						<p v-if="oa_users[scope.row.employee_id] && oa_users[scope.row.employee_id].main_department && oa_departments[oa_users[scope.row.employee_id].main_department]">
							{{oa_departments[oa_users[scope.row.employee_id].main_department].name}}
						</p>
						<p v-else-if="scope.row.employee.shop">{{scope.row.employee.shop.name}}</p>
					</template>
				</el-table-column>
				<el-table-column label="流水" min-width="220">
					<template slot-scope="scope">
						<el-link type="primary" :disabled="!$utils.update($api.URI_EMPLOYEES_EXPENSES)" @click="showExpense({...scope.row, common_no: scope.row.common ? scope.row.common.no : '', amount: scope.row.amount/100})">{{scope.row.no}}</el-link>
						<p style="color: #999; font-size: 12px;" v-if="scope.row.common">{{common_types[scope.row.common_type] || '未知'}}No.{{scope.row.common.no}}</p>
					</template>
				</el-table-column>
				<el-table-column label="报销公司" min-width="200" :formatter="({company}) => { return company ? company.name : '不限'}"></el-table-column>
				<el-table-column label="金额" min-width="80" :formatter="({amount}) => { return amount/100 + ' 元'}"></el-table-column>
				<el-table-column label="用途" min-width="80" :formatter="({purpose}) => { return purposes[purpose] || '未知'}"></el-table-column>
				<el-table-column label="领取方式" min-width="80" :formatter="({get}) => { return get ? '其它' :'工资'}"></el-table-column>
				<el-table-column label="状态" min-width="80">
					<template slot-scope="scope">
						<el-tooltip effect="dark" placement="top" :content="'OA审核编号'+scope.row.approval.sp_no" v-if="scope.row.approval">
							<el-link type="primary" @click="$refs['expense_show'].onStatus(scope.row)">{{statuses[scope.row.status] || '未知'}}</el-link>
						</el-tooltip>
						<el-link type="primary" @click="$refs['expense_show'].onStatus(scope.row)" v-else>{{statuses[scope.row.status] || '未知'}}</el-link>
					</template>
				</el-table-column>
				<el-table-column label="备注说明" prop="remarks" min-width="160"></el-table-column>
				<el-table-column label="操作时间" width="210" v-if="theme.width >= 1240">
					<template slot-scope="scope">
						<p>更新：{{scope.row.updated_at}}</p>
						<p>创建：{{scope.row.created_at}}</p>
					</template>
				</el-table-column>
				<el-table-column width="80">
					<el-button slot="header" type="primary" size="mini" @click="showExpense({common_type: 'App\\Models\\Order'})">申请</el-button>
					<el-button slot-scope="scope" type="text" :disabled="!$utils.delete($api.URI_EMPLOYEES_EXPENSES)" @click="$refs['expense_show'].onDelete(scope.row)">删除</el-button>
				</el-table-column>
			</el-table>
			<el-pagination
				slot="footer"
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getExpenses({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getExpenses({ ...params, page: v }) }"
				background>	
			</el-pagination>
		</main-table>
		<expense-show ref="expense_show" :types="common_types" :purposes="purposes" :companies="companies" :status="statuses" @refresh="getExpenses(params)"></expense-show>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import mainTable from '@/components/main-table';
	import expenseShow from './show';

	export default {
		components: {
			expenseShow,
			mainTable
		},
		computed: {
			...mapState(['theme', 'oa_users', 'oa_departments'])
		},
		methods: {
			showExpense (r) {
				if (r.status == 4 || r.status == 5) {
					r.verifications = r.attachments.filter((a) => a.meta === 1);
					r.attachments = r.attachments.filter((a) => a.meta === 0);
				}
				this.$refs['expense_show'].open(r);
			},
			async getExpenses (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_EMPLOYEES_EXPENSES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.expenses = result.data;
				this.statuses = result.statuses;
				this.purposes = result.purposes;
				this.employees = result.employees;
				this.companies = result.companies;
				this.common_types = result.common_types;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				expense: {},
				expenses: [],
				statuses: [],
				purposes: {},
				employees: [],
				companies: [],
				common_types: {},
				expense_dialog: false,
				params: {
					perPage: 10,
				}
			}
		},
		created () {
			this.getExpenses(this.params, true);
		}
	};
</script>